<template>
  <div class="hold-transition sidebar-mini">

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>{{object.name}}</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><router-link :to="{name:'Currencies'}">
                  إدارة العملات
                </router-link></li>
                <li class="breadcrumb-item active">تعديل العملة</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- jquery validation -->
              <div class="card card-primary">

                <!-- /.card-header -->
                <!-- form start -->
                <form id="quickForm" @submit.prevent="updateCurrency">
                  <div class="card-body">

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            رمز العملة
                          </label>
                          <input type="text"  class="form-control"
                                 v-model="object.code"
                                 placeholder="أدخل رمز العملة">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الاسم
                          </label>
                          <input type="text" name="" class="form-control"
                                 v-model="object.name"
                                 placeholder="أدخل الاسم">
                        </div>
                      </div>
                    </div>




                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button type="submit" class="btn "  style="background-color: #8a7a25; color: white" >حفظ</button>
                  </div>
                </form>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
            <div class="col-md-6">

            </div>
            <!--/.col (right) -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowCurrency",
  data() {
    return {
      object: {}
    }
  }, methods: {
    getObject() {

      this.$store.dispatch("SHOW_CURRENCY", this.$route.params.id)
          .then((res) => {
            console.log(res.data)
            this.object = this.$store.state.currencies.selected

          }).catch((err) => {
        this.errors = err.response.data.errors;
      });
    },
    updateCurrency(){
      this.$store.dispatch("LOADING");
      var payload = {
        currency_id: this.$route.params.id,
        name:this.object.name,
        code:this.object.code
      }
      this.$store.dispatch("UPDATE_CURRENCY", payload)
          .then((res) => {
            console.log(res.data)
            this.object = this.$store.state.currencies.selected
            this.$store.dispatch("STOP_LOADING");
            this.$router.push({name:'Currencies'});

          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;

      });
    }
  },
  created() {
    this.getObject()
  }
}
</script>

<style scoped>

</style>