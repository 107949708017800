import axios from '../main_axios';
import store from "../index";

export const users = {
    state: {
        data: [],

        selected: null

    },
    getters: {},
    actions: {

        GET_LIST_USERS(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/user-manager').then((response) => {
                    var list = []
                    response.data.data.map(function (value) {
                        list.push(value)
                    })
                    context.commit('GET_LIST_USERS', list)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },
        SHOW_USER(context, id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/user-manager/show/' + id).then((response) => {

                    context.commit('SET_USER', response.data.data)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        ADD_USER(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/user-manager', payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        UPDATE_USER(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/user-manager/update', payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        TOGGLE_BLOCK_USER(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/user-manager/toggle-block', payload).then((response) => {
                    context.commit('SET_USER', response.data.data)
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        }

    },
    mutations: {
        GET_LIST_USERS(state, items) {
            state.data = items;
        },
        SET_USER(state, item) {
            state.selected = item;
        }

    }

}