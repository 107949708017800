<template>
  <div class="content-wrapper ">
    <div class="content-header ">
      <div class="container-fluid  mt-5">
        <div class="row mb-2">
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item">
                <router-link :to="{name:'AddUser'}" class="btn " style="background-color: #8a7a25; color: white">
                  إضافة مستخدم جديد
                </router-link>
              </li>
            </ol>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">

                <h1 class="m-0">
                  إدارة المستخدمين
                </h1>

              </li>

            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">


            <!-- /.card-header -->

            <table id="example1" class="table table-striped">
              <thead>
              <tr>
                <th>
                  اسم المستخدم
                </th>
                <th>
                  البريد الالكتروني
                </th>
                <th>
                  الاسم
                </th>
                <th>
                  رقم الهاتف
                </th>
                <th>
                  الصلاحية
                </th>
                <th>
                  حالة الحساب
                </th>
                <th>

                </th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="data in users" :key="data.id">

                <td>{{ data.user_name }}</td>
                <td >{{ data.email }}</td>
                <td>{{ data.name }}</td>
                <td class="english-font"> {{ data.phone_number }}</td>
                <td>{{ data.user_role==='AD'?'مدير':'مساعد مدير' }}</td>
                <td>
                  <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success ">
                    <input type="checkbox" class="custom-control-input" @change="toggleBlock(data.id)" :id="'customSwitch'+data.id" :checked="!data.is_blocked">
                    <label class="custom-control-label" :for="'customSwitch'+data.id">
                      تفعيل /حظر
                    </label>
                  </div>
                </td>
                <td>
                  <router-link :to="'/admin/users/show/'+data.id">
                    تعديل
                  </router-link>

                </td>
              </tr>


              </tbody>

            </table>

            <!-- /.card-body -->

          </div>
        </div>
      </div>
    </section>
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Users",
  data() {
    return {
      users: [],
      errors: {}
    }
  }, computed: {},
  methods: {
    getList() {
      this.$store.dispatch("LOADING");
      this.$store.dispatch("GET_LIST_USERS")
          .then((res) => {
            console.log(res.data)
            this.users = this.$store.state.users.data
            this.$store.dispatch("STOP_LOADING");
          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;
      });
    }  ,
    toggleBlock(id) {
      this.$store.dispatch("LOADING");
      var payload={
        user_id:id
      }
      this.$store.dispatch("TOGGLE_BLOCK_USER",payload)
          .then((res) => {

            console.log(res.data)
            this.users = this.$store.state.users.data
            this.$store.dispatch("STOP_LOADING");
          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;
      });
    }
  },
  created() {
    this.getList()
  }
}

</script>

<style scoped>

</style>