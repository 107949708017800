<template>

  <div class="row div-golden ">
    <div class="row" style="font-size: 25px">


      <div class="col">

        <label>
          كل
          <span style="color: white">واحد </span>
          <span style="color: white">{{ currency.currency_selling_name }}</span>
          يعادل بالـ{{ currency.currency_buy_name }}
        </label>
      </div>

    </div>

  </div>
  <div class="d-flex justify-content-around mt-3">
    <div class="d-flex flex-column ">
      <div class=" ">

        <div class="row ">
          <div class=" currency_price mr-5 ml-5  mb-2 " style="width: 50%">
            <label>
              مبيع
            </label>
          </div>
        </div>
        <div class="row">
          <input type="number"
                 v-model="currency_buy_price"
                 class="currency_price english-font mr-5 ml-5  mb-2"
                 style="width: 50%">
        </div>
      </div>
    </div>
    <!--                column-->
    <div class=" d-flex flex-column ">
      <div class="">


        <div class="row">
          <div class=" currency_price ml-5 mb-2 " style="width: 50%">
            <label>
              شراء
            </label>
          </div>
        </div>

        <div class="row">
          <input type="number" v-model="currency_selling_price" class="currency_price english-font ml-5 mb-2"
                 style="width: 50%">
        </div>
      </div>
    </div>
  </div>
  <div class="bar-golden"></div>

</template>

<script>
export default {
  name: "CurrencyPrice",
  props: {
    currency_price_active: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currency: null,
      currency_buy_price: parseFloat(this.currency_price_active.currency_buy_price).toFixed(2),
      currency_selling_price: parseFloat(this.currency_price_active.currency_selling_price).toFixed(2)
    }
  },
  computed: {
    // currency_selling_price() {
    //   return parseFloat(this.currency.currency_selling_price).toFixed(1)
    // },
    // currency_buy_price() {
    //   return parseFloat(this.currency.currency_buy_price).toFixed(1)
    // },

  },
  watch: {
    currency_buy_price(val) {


      this.currency.currency_buy_price = val;
    },

    currency_selling_price(val) {
      if (this.currency.id === 4) {
        this.priceSale_USD_SYP = val;
        this.priceSale_TL_SYP=  parseFloat((this.priceSale_USD_SYP / this.priceBUY_USD_TL)).toFixed(2)
      }
      if(this.currency.id === 2){
        this.priceSale_USD_TL = val;
      }
      this.currency.currency_selling_price = val;
    }
  },
  created() {
    this.currency = this.currency_price_active

  }
}
</script>


<style scoped lang="css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number] {
  border: 2px solid #8a7a25;
  border-radius: 4px;
}

.div-golden {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 2px none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #8a7a25;
}

.bar-golden {

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 2px none;
  padding: 2.5px;

  background-color: #e2b746;
}


.currency_price {
  background-color: #343a40;
  border: none;
  text-align: center;
  color: white;

}

</style>