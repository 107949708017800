<template>
  <div>
    <div class="wrapper">

      <!-- Preloader -->
      <div class="preloader flex-column justify-content-center align-items-center">
        <img class="animation__shake" src="/admin/dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
      </div>

      <nav class="main-header navbar navbar-expand navbar-dark">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
          </li>

        </ul>

        <!-- Right navbar links -->

      </nav>
      <side-bar v-if="isAuth"></side-bar>


      <!-- Content Wrapper. Contains page content -->
      <router-view></router-view>
      <!-- /.content-wrapper -->

      <!--      <Footer></Footer>-->
      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->
    </div>

  </div>
  <!--  <router-view/>-->
</template>
<script>
import SideBar from "./components/layout/SideBar";
// import Footer from "./components/layout/Footer";

export default {
  data() {
    return {}
  },
  computed: {
    isAuth() {
      return this.$store.getters.GET_AUTH_STATUS;
    }
  },
  components: {
    SideBar,
    // Footer
  },

}
</script>


