<template>
  <div class="hold-transition sidebar-mini">

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>
                إضافة إعلان جديد
              </h1>
            </div>

            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="{name:'Ads'}">
                    إدارة الإعلانات
                  </router-link>
                </li>
                <li class="breadcrumb-item active">
                  إضافة إعلان
                </li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- jquery validation -->
              <div class="card card-primary">
                <div v-if="success_add" class="alert alert-success alert-dismissible fade show " role="alert">
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    <span class="sr-only">Close</span>
                  </button>
                  تمت اضافة الإعلان بنجاح
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form id="quickForm" @submit.prevent="addAD">
                   <span class="text-danger" v-if="errors">

           </span>
                  <div class="card-body">
                    <div class="row">

                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>
                                الاسم
                              </label>
                              <input type="text" name="" required class="form-control"
                                     v-model="object.name"
                                     placeholder="أدخل الاسم">
                              <label class="text-danger" v-if="errors.name">
                                يرجى اضافة الاسم
                              </label>
                            </div>
                          </div>

                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <div
                                  class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitch3"
                                       v-model="object.is_active">
                                <label class="custom-control-label" for="customSwitch3">
                                تفعيل / حظر
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <div
                                  class="">
                                <!--                            <ImageLoader :Images="object.image" keyName="id" valueName="i" />-->
                                <input type="file" required @change="onChange" accept="image/*">

                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-3"></div>
                      <div class="col-md-3">
                        <img :src="urlImage!== null ?urlImage:'/admin/dist/img/ad with us.png'"
                             class="img-fluid mb-2"
                             alt="white sample"/>
                      </div>


                    </div>


                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button type="submit" class="btn " style="background-color: #8a7a25; color: white">حفظ</button>
                  </div>

                </form>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->

            <!--/.col (right) -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
// import ImageLoader from "vue-picture-upload";
// import fileBase64 from 'vue-file-base64';

export default {
  name: "AddAD",

  data() {
    return {
      success_add: false,
      urlImage: null,
      errors: {
        name: null,
        link: null,
        image: null,
        is_active: false
      },
      object: {
        name: null,
        link: null,
        image: null,
        is_active: false
      }
    }
  }, methods: {
    onChange(e) {

      // get the files
      let files = e.target.files;

      // Process each file

      for (var i = 0; i < files.length; i++) {

        let file = files[i]

        // Make new FileReader
        let reader = new FileReader()

        // Convert the file to base64 text
        reader.readAsDataURL(file)

        // on reader load somthing...
        reader.onload = () => {

          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + ' kB',
            base64: reader.result,
            file: file
          }
          this.urlImage = URL.createObjectURL(file);
          // Push it to the state
          this.object.image = fileInfo.base64

          // If all files have been proceed

          // Apply Callback function


        } // reader.onload

      } // for

    },

    addAD() {

      this.$store.dispatch("LOADING");
      this.$store.dispatch("ADD_AD", this.object)
          .then((res) => {

            console.log(res.data)
            // this.object = this.$store.state.users.selected
            this.$store.dispatch("STOP_LOADING");
            this.success_add = true;
            this.object = {
              name: null,
              link: null,
              image: null,
              is_active: false
            }
            this.errors = {
              name: null,
              link: null,
              image: null,
              is_active: false
            }
            // this.$router.push({name: 'Users'})
          }).catch((err) => {
        this.errors = err.response.data.errors;
        this.$store.dispatch("STOP_LOADING");
        // this.errors.password = err.response.data.errors.password !== undefined ? this.errors.password[0] : null
        // this.errors.email = err.response.data.errors.email !== undefined ? this.errors.email[0] : null
        // this.errors.phone_number = err.response.data.errors.phone_number !== undefined ? this.errors.phone_number[0] : null
        this.errors.name = err.response.data.errors.name !== undefined ? this.errors.name[0] : null
        // this.errors.role = err.response.data.errors.role !== undefined ? this.errors.role[0] : null
      }).finally(() => {
        this.$store.dispatch("STOP_LOADING");
      });
    }
  },
  created() {

  }
}
</script>
<style lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>