<template>
  <div class="content-wrapper ">
    <div class="content-header ">

    </div>
    <section class="content">
      <div class="container-fluid">

        <!--        <div class="row ">-->
        <!--          <div class="col-5"></div>-->
        <!--          <div class="col-6">-->
        <!--            <div class="user-panel mt-3 pb-3 mb-3 d-flex">-->
        <!--              <div class="image">-->
        <!--                <img src="/admin/dist/img/AdminLTELogo.png" class="img-circle  w-25 " alt="User Image">-->
        <!--                &lt;!&ndash;          <img :src="getImageProfile" class="img-circle elevation-2 w-100 h-100" alt="User Image">&ndash;&gt;-->
        <!--              </div>-->
        <!--              &lt;!&ndash;        <div class="info">&ndash;&gt;-->
        <!--              &lt;!&ndash;          <a href="#" class="d-block">Alexander Pierce</a>&ndash;&gt;-->
        <!--              &lt;!&ndash;        </div>&ndash;&gt;-->
        <!--            </div>-->
        <!--          </div>-->


        <!--          &lt;!&ndash; /.card-header &ndash;&gt;-->


        <!--          &lt;!&ndash; /.card-body &ndash;&gt;-->


        <!--        </div>-->
        <div class="row">
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-info">
              <div class="inner">
                <h3>{{ count_download }}</h3>

                <p>
                  التحميلات الإجمالية
                </p>
              </div>

              <a href="#" class="small-box-footer" @click="updateDownload">
                تحديث
                <i class="fa fa-retweet" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->

          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->

          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
              <div class="image">
                <img src="/admin/dist/img/AdminLTELogo.png" class="img-circle  w-25 " alt="User Image">
                <!--          <img :src="getImageProfile" class="img-circle elevation-2 w-100 h-100" alt="User Image">-->
              </div>
              <!--        <div class="info">-->
              <!--          <a href="#" class="d-block">Alexander Pierce</a>-->
              <!--        </div>-->
            </div>
          </div>
          <!-- ./col -->
        </div>
        <ul class="nav nav-tabs " id="myTab" role="tablist">
          <li class="nav-item" style=" margin-left: auto;
  margin-right: auto;
  width: 50%;">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
               aria-selected="true">
              العملات
            </a>
          </li>
          <li class="nav-item" style=" margin-left: auto;
  margin-right: auto;
  width: 50%;">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
               aria-selected="false">
              الحاسبة
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="items-center mt-5" v-for="currency in currencies_prices_actives" :key="currency.id"
                 style="background-color: #1d1d1d;border-top-left-radius: 25px; border-top-right-radius: 25px;">
              <CurrencyPrice style="margin: 10px" :currency_price_active="currency"/>
            </div>
            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-4">
                <button class=" btn" style="background-color:#8a7a25;color: white;margin:20px" @click="update">
                  تحديث الأسعار
                </button>
              </div>
              <div class="col-md-3"></div>
            </div>
          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="items-center mt-5" v-for="currency in currencies_prices_actives" :key="currency.id"
                 style="background-color: #1d1d1d;border-top-left-radius: 25px; border-top-right-radius: 25px;">
              <CalculatorPrice style="margin: 10px" :currency_price_active="currency"/>
            </div>
          </div>


        </div>


      </div>
    </section>

    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyPrice from "../../components/currency_price/CurrencyPrice";
import CalculatorPrice from "../../components/currency_price/CalculatorPrice";

export default {
  name: "CurrenciesPricesActives",
  components: {
    CurrencyPrice,
    CalculatorPrice
  },
  data() {
    return {
      currencies_prices_actives: [],
      count_download: 0,
      errors: {}
    }
  },
  methods: {
    getList() {
      this.$store.dispatch("GET_LIST_ACTIVE_CURRENCIES_PRICES")
          .then((res) => {
            this.$store.dispatch("STOP_LOADING")
            console.log(res.data)
            this.currencies_prices_actives = this.$store.state.currencies_prices.data_active

          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING")
        this.errors = err.response.data.errors;

      });
    },
    update() {
      var currencyPricesList = []
      this.currencies_prices_actives.map(function (value) {
        currencyPricesList.push({
          currency_price_id: Number(value.id),
          currency_selling_price: parseFloat(value.currency_selling_price),
          currency_buy_price: parseFloat(value.currency_buy_price)
        })
      })
      var payload = {

        currencyPrices: currencyPricesList
      }
      this.$store.dispatch("LOADING")
      this.$store.dispatch("UPDATE_CURRENCY_PRICE", payload)
          .then((res) => {
            this.$store.dispatch("STOP_LOADING")
            console.log(res.data)
            this.currencies_prices_actives = this.$store.state.currencies_prices.data_active

            // location.reload();
          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING")
        this.errors = err.response.data.errors;

      });
    },
    updateDownload() {
       this.$store.dispatch("LOADING")
      this.$store.dispatch("UPDATE_DOWNLOAD")
          .then((res) => {
            console.log(res.data)
            this.count_download = this.$store.state.currencies_prices.count
             this.$store.dispatch("STOP_LOADING")
          }).catch((err) => {
        this.errors = err.response.data.errors;
         this.$store.dispatch("STOP_LOADING")
      });
    }
  },
  created() {
    this.getList()
    this.$store.dispatch("UPDATE_DOWNLOAD")
        .then((res) => {
          this.$store.dispatch("STOP_LOADING")
          console.log(res.data)
          this.count_download = this.$store.state.currencies_prices.count

        }).catch((err) => {
      this.$store.dispatch("STOP_LOADING")
      this.errors = err.response.data.errors;

    });
  }
}

</script>

<style scoped lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.items-center {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
}


@media only screen and (max-width: 600px) {
  .items-center {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
}

</style>