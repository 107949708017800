<template>
  <div class="content-wrapper ">
    <div class="content-header ">
      <div class="container-fluid  mt-5">
        <div class="row mb-2">
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item">
                <router-link :to="{name:'AddAD'}" class="btn " style="background-color: #8a7a25; color: white">
                  إضافة إعلان جديد
                </router-link>
              </li>
            </ol>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">

                <h1 class="m-0">
                  إدارة الإعلانات
                </h1>

              </li>

            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row" v-if="ads.length>0">
          <div class="col-12">
            <div class="card card-primary">

              <div class="card-body">
                <div class="row">
                  <div class="col-sm-2 mr-2 " v-for="(data,index) in ads" :key="data.id">
                    <div class="row ml-2 mb-2">
                      <div class="col-6  " >
                        <a href="#" @click="deleteAd(data.id)">
                          <i class="fa fa-trash text-center text-danger"></i>
                        </a>
                      </div>
                      <div class="col-6  " >
                        <router-link :to="'/admin/ads/show/'+data.id" >
                          <i class="fa fa-edit text-center "></i>
                        </router-link>
                      </div>


                    </div>
                    <div class="row">
                      <a href="#"
                         @click="showLightBox=true;index_image = index">
                        <img :src="data.image.src"
                             class="img-fluid mb-2"
                             alt="white sample"/>
                      </a>
                      <div class="ribbon-wrapper ">
                        <div :class="data.is_active?'ribbon bg-success':'ribbon bg-danger'">
                          {{ data.is_active?'مفعل' :'غير مفعل'}}
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </div>
            <vue-easy-lightbox
                :visible="showLightBox"
                :imgs="media"
                :index="index_image"
                @hide="handleHide"
                style="z-index: 900000 !important;"
            ></vue-easy-lightbox>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-6">
          </div>
          <div class="col-6">
            لا يوجد إعلانات
          </div>
        </div>
      </div>

    </section>
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: "Ads",
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      ads: [],
      errors: {},
      showLightBox: false,
      index_image: 0,
    }
  }, computed: {
    media() {
      var media = []
      for (var i = 0; i < this.ads.length; i++) {
        media.push(
            {
              src: this.ads[i].image.src
            })

      }

      return media
    }
  },
  methods: {
    getList() {
      this.$store.dispatch("LOADING");
      this.$store.dispatch("GET_LIST_ADS")
          .then((res) => {
            console.log(res.data)
            this.ads = this.$store.state.ads.data
            this.$store.dispatch("STOP_LOADING");
          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;
      });
    },
    toggleBlock(id) {
      this.$store.dispatch("LOADING");
      var payload = {
        ad_id: id
      }
      this.$store.dispatch("TOGGLE_ACTIVE_AD", payload)
          .then((res) => {

            console.log(res.data)
            this.ads = this.$store.state.ads.data
            this.$store.dispatch("STOP_LOADING");
          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;
      });
    },
    handleHide() {
      this.showLightBox = false
    },
    deleteAd(id) {
      this.$store.dispatch("LOADING");
      var payload = {
        ad_id: id
      }
      this.$store.dispatch("DELETE_AD", payload)
          .then((res) => {

            console.log(res.data)
            this.getList()
            this.$store.dispatch("STOP_LOADING");
          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;
      });
    }
  },
  created() {
    this.getList()
  }
}

</script>

<style scoped>
.ribbon-wrapper {
  height: 70px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 30px;
  width: 70px;
  z-index: 10;
}
</style>