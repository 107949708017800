<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <a href="#" class="h1">
            النور للصرافة والحوالات
          </a>
        </div>
        <div class="card-body">
<!--          <p class="login-box-msg">-->
<!--           -->
<!--          </p>-->

          <form @submit.prevent="adminLogin">
           <span class="text-danger" v-if="errors.user_name">
                الاسم المستخدم خاطئ
           </span>
            <span class="text-danger" v-if="errors.loginError">
              <div class="alert alert-danger alert-dismissible fade show " role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  <span class="sr-only">Close</span>
                </button>
                                فشل التسجيل تأكد من إدخالاتك

              </div>
           </span>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="اسم المستخدم" v-model="form.user_name">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-user-circle"></span>
                </div>
              </div>
            </div>
            <span class="text-danger" v-if="errors.password">
            الحد الأدني 8 محارف
           </span>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="كلمة السر" v-model="form.password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary">
                  <input type="checkbox" id="remember" v-model="form.remember_me">
                  <label for="remember">
                    تذكرني
                  </label>
                </div>
              </div>
              <!-- /.col -->
              <div class="col-4">
                <button type="submit" class="btn btn-primary btn-block">
                  دخول
                </button>
              </div>
              <!-- /.col -->
            </div>
          </form>


          <!-- /.social-auth-links -->

          <p class="mb-1">
            <router-link :to="{name:'ForgotPassword'}">
              نسيت كلمة السر
            </router-link>
          </p>

        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        user_name: "",
        password: "",
        remember_me: false
      }, errors: {}
    }
  },
  methods: {
    adminLogin() {
      this.$store.dispatch("LOADING")
      this.$store.dispatch("LOGIN", this.form)
          .then((res) => {
            console.log(res.data)
            this.$store.dispatch("STOP_LOADING")
            this.$router.push({name: 'CurrenciesPricesActives'})
          }).catch((err) => {
        this.errors = err.response.data.errors;
        this.$store.dispatch("STOP_LOADING")
      });
    }
  }

}
</script>

<style lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>