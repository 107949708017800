import {createApp} from 'vue'


import App from './App.vue'
import router from './router'
import store from './store'
// import acl from './acl/acl'

// createApp.config.productionTip = false
// new createApp({
//     router,
//     store,
//     // acl,
//     render: h => h(App)
// }).$mount('#app')
const config = createApp(App)

config.use(store).use(router).mount('#app')