import {createRouter, createWebHistory} from 'vue-router'
import store from "../store";

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import Home from '../views/layout/Home.vue'
import Users from '../views/users/Index.vue'
import ShowUser from '../views/users/Show.vue'
import AddUser from '../views/users/Add.vue'
import Currencies from '../views/currencies/Index.vue'
import ShowCurrency from '../views/currencies/Show.vue'
import AddCurrency from '../views/currencies/Add.vue'
import CurrenciesPrices from '../views/currencies_prices/Index.vue'
import CurrenciesPricesActives from '../views/currencies_prices/IndexActives.vue'
import Ads from '../views/ads/Index.vue'
import AddAD from '../views/ads/Add.vue'
import ShowAd from '../views/ads/Show.vue'
import AboutUs from '../views/about_us/AboutUs.vue'

const routes = [

    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin/auth/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/admin/home',
        name: 'Home',
        component: Home,
        meta: {
            auth: true,

        }
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: Users,
        meta: {
            auth: true,

        }
    },
    {
        path: '/admin/users/show/:id',
        name: 'ShowUsers',
        props: true,
        component: ShowUser,
        meta: {
            auth: true,

        }
    },
    {
        path: '/admin/users/add',
        name: 'AddUser',
        component: AddUser,
        meta: {
            auth: true,
        }
    },
    {
        path: '/admin/currencies',
        name: 'Currencies',
        component: Currencies,
        meta: {
            auth: true,

        }
    },
    {
        path: '/admin/currencies/show/:id',
        name: 'ShowCurrency',
        props: true,
        component: ShowCurrency,
        meta: {
            auth: true,

        }
    },
    {
        path: '/admin/currencies/add',
        name: 'AddCurrency',
        component: AddCurrency,
        meta: {
            auth: true,

        }
    },
    {
        path: '/admin/currencies-prices',
        name: 'CurrenciesPrices',
        component: CurrenciesPrices,
        meta: {
            auth: true,

        }
    },
    {
        path: '/',
        name: 'CurrenciesPricesActives',
        component: CurrenciesPricesActives,
        meta: {
            auth: true,
        }
    },
    {
        path: '/admin/ads',
        name: 'Ads',
        component: Ads,
        meta: {
            auth: true,
        }
    },
    {
        path: '/admin/ads/add',
        name: 'AddAD',
        component: AddAD,
        meta: {
            auth: true,
        }
    },
    {
        path: '/admin/ads/show/:id',
        name: 'ShowAd',
        component: ShowAd,
        meta: {
            auth: true,
        }
    },
    {
        path: '/admin/about-us',
        name: 'AboutUs',
        component: AboutUs,
        meta: {
            auth: true,
        }
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (!store.getters.GET_AUTH_STATUS) {
            next({
                name: 'Login',
            })
        } else next()
    } else next()
})
export default router
