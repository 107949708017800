import axios from '../main_axios';
import store from "../index";

export const currencies = {
    state: {
        data: [],
        selected: null

    },
    getters: {


    },
    actions: {

        GET_LIST_CURRENCIES(context) {
            axios.defaults.headers.common['Authorization'] ='Bearer '+ store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/currency').then((response) => {
                    var list = []
                    response.data.data.map(function (value) {
                        list.push(value)
                    })
                    context.commit('GET_LIST_CURRENCIES',list)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },
        SHOW_CURRENCY(context,id){
            axios.defaults.headers.common['Authorization'] ='Bearer '+ store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/currency/show/'+id).then((response) => {

                    context.commit('SET_CURRENCY',response.data.data)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        }  ,
        ADD_CURRENCY(context,payload){
            axios.defaults.headers.common['Authorization'] ='Bearer '+ store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/currency',payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        UPDATE_CURRENCY(context,payload){
            axios.defaults.headers.common['Authorization'] ='Bearer '+ store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/currency/update',payload).then((response) => {
                    context.commit('SET_CURRENCY',response.data.data)
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        }

    },
    mutations: {
        GET_LIST_CURRENCIES(state, items) {
            state.data = items;
        },
        SET_CURRENCY(state, item) {
            state.selected = item;
        }

    }

}