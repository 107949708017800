<template>
  <div class="hold-transition sidebar-mini">

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>
                إضافة عملة جديدة
              </h1>
            </div>

            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="{name:'Currencies'}">
                    إدارة العملات
                  </router-link>
                </li>
                <li class="breadcrumb-item active">
                  إضافة عملة
                </li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- jquery validation -->
              <div class="card card-primary">
                <div v-if="success_add" class="alert alert-success alert-dismissible fade show " role="alert">
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    <span class="sr-only">Close</span>
                  </button>
                  تمت اضافة السجل بنجاح
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form id="quickForm" @submit.prevent="addCurrency">

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            رمز العملة
                          </label>

                          <input type="text" class="form-control"
                                 v-model="object.code"
                                 placeholder="أدخل رمز العملة">
                          <label class="text-danger"
                                 v-if="errors.code === 'The code has already been taken.'">
                            الاسم موجود مسبقا الرجاء تغيير رمز العملة
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الاسم
                          </label>
                          <input type="text" name="" required class="form-control"
                                 v-model="object.name"
                                 placeholder="أدخل الاسم">
                          <label class="text-danger" v-if="errors.name">
                            يرجى اضافة الاسم
                          </label>
                        </div>
                      </div>
                    </div>



                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">حفظ</button>
                  </div>

                </form>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
            <div class="col-md-6">

            </div>
            <!--/.col (right) -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
// import ImageLoader from "vue-picture-upload";
// import fileBase64 from 'vue-file-base64';

export default {
  name: "AddCurrency",

  data() {
    return {
      success_add: false,
      errors: {
        code: null,
        name: null,

        is_active: null,
      },
      object: {
        code: null,
        name: null,
        is_active: true,

      }
    }
  }, methods: {


    addCurrency() {

      this.$store.dispatch("LOADING");
      this.$store.dispatch("ADD_CURRENCY", this.object)
          .then((res) => {

            console.log(res.data)
            // this.object = this.$store.state.users.selected
            this.$store.dispatch("STOP_LOADING");
            this.success_add = true;
            this.object = {
              name: null,
              code: '',
              is_active: false
            }
            this.errors = {
              name: null,
              code: null,
              is_active: null,

            }
            // this.$router.push({name: 'Users'})
          }).catch((err) => {
        this.errors = err.response.data.errors;
        this.$store.dispatch("STOP_LOADING");

        this.errors.code = err.response.data.errors.code !== undefined ? this.errors.code[0] : null

      }).finally(()=>{
        this.$store.dispatch("STOP_LOADING");
      });
    }
  },
  created() {

  }
}
</script>
<style lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>