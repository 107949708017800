<template>
  <div class="row div-golden ">
    <div class="row">
      <div class="col">
        <label>{{ currency.currency_buy_name }}</label>
      </div>
      <div class="col">
        <label>{{ currency.currency_selling_name }}</label>
      </div>

    </div>

  </div>
  <div class="d-flex justify-content-around">
    <div class="d-flex flex-column " style="width: 50%">
      <div class="row">
        <div class="button-radius mr-5 ml-3 mb-2 mt-2" @click="calc_buy">
          <label class=" m-1" style="font-size: 25px">
            مبيع
          </label>
        </div>
      </div>
    </div>
    <div class=" d-flex flex-column " style="width: 50%">


      <div class="row">
        <div class="  button-radius ml-5 mr-3 mb-2 mt-2" @click="calc_sell">
          <label class="m-1" style="font-size: 25px">
            شراء
          </label>
        </div>
      </div>

    </div>
  </div>

  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column ">

      <div class="row">
        <label
            class=" english-font mr-5 ml-5 mt-1 mb-2"
            style="width: 50%;color: white;font-size: 10px">
          {{ currency_buy_price }}
        </label>

      </div>
    </div>

    <div class=" d-flex flex-column ">

      <div class="row">
        <input type="number" v-model="qty" class="currency_price english-font  mb-2" style="width: 100% "
               :placeholder=" 'أدخل القيمة بال ' +currency.currency_selling_name">
      </div>
    </div>
    <div class=" d-flex flex-column ">
      <label
          class=" english-font mr-5 ml-5 mt-1 mb-2"
          style="width: 50%;color: white;font-size: 10px">
        {{ currency_selling_price }}
      </label>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column " style="width: 50%">

      <div class=" currency_price mr-5 " style="font-size: 25px">
        <label>
          {{ currency.currency_buy_name }}
        </label>
      </div>
    </div>


    <div class=" d-flex flex-column " style="width: 100%">
      <div class=" currency_price  ml-5   " style="font-size: 25px">
        <label>
          {{ result }}
        </label>
      </div>
    </div>
  </div>

  <div class="bar-golden"></div>

</template>

<script>
export default {
  name: "CalculatorPrice",
  props: {
    currency_price_active: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currency: null,
      qty: null,
      result: null
    }
  },
  computed: {
    currency_selling_price(){
      return parseFloat(this.currency_price_active.currency_selling_price).toFixed(1)
    },
    currency_buy_price(){
      return parseFloat(this.currency_price_active.currency_buy_price).toFixed(1)
    },
  },
  methods: {
    calc_buy() {
      if (this.qty !== null)
       this.result= this.currency.currency_buy_price * this.qty;
    },
    calc_sell() {
      if (this.qty !== null)
        this.result= this.currency.currency_selling_price * this.qty;
    }
  },
  created() {
    this.currency = this.currency_price_active
  }
}
</script>


<style scoped lang="css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number] {
  border: 2px solid #8a7a25;
  border-radius: 4px;
}

.div-golden {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 2px none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #8a7a25;
}

.button-radius {
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: white;
  border-radius: 25px;
  background-color: #8a7a25;
}

.bar-golden {

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 2px none;
  padding: 2.5px;

  background-color: #e2b746;
}


.currency_price {
  background-color: #343a40;
  border: none;
  text-align: center;
  color: white;

}

</style>