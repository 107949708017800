import axios from '../main_axios';
import store from "../index";

export const ads = {
    state: {
        data: [],
        selected: null

    },
    getters: {},
    actions: {

        GET_LIST_ADS(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/ads-manager').then((response) => {
                    var list = []
                    response.data.data.map(function (value) {
                        list.push(value)
                    })
                    context.commit('GET_LIST_ADS', list)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },

        SHOW_AD(context, id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/ads-manager/show/' + id).then((response) => {

                    context.commit('SET_AD', response.data.data)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        ADD_AD(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/ads-manager', payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        UPDATE_AD(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/ads-manager/update', payload).then((response) => {
                    context.commit('SET_AD', response.data.data)
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        TOGGLE_ACTIVE_AD(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/ads-manager/toggle-block', payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        DELETE_AD(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/ads-manager/delete', payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },


    },
    mutations: {
        GET_LIST_ADS(state, items) {
            state.data = items;
        },
        SET_AD(state, item) {
            state.selected = item;
        }

    }
}