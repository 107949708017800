import axios from "axios";
// import store from "../store";

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = 'https://alnoorexchange.com/api';
// axios.defaults.headers.common['Authorization'] ='Bearer '+ store.getters.GET_AUTH_TOKEN;
export default axios.create({
    axios

    // You can add your headers here
})