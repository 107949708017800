<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <a href="#" class="h1">
            النور للصرافة والحوالات
          </a>
        </div>
        <div class="card-body">
          <p class="login-box-msg">
            يمكنك كتابة البريد الالكتروني من أجل إعادة كتابة المرور
          </p>
          <form >
            <div class="input-group mb-3">
              <input type="email" class="form-control" placeholder="البريد الالكتروني">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block">
                  تغيير كلمة السر
                </button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          <p class="mt-3 mb-1">
            <router-link :to="{name:'Login'}">
             تسجيل الدخول
            </router-link>
          </p>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword"
}
</script>

<style scoped>

</style>