<template>
  <div class="hold-transition sidebar-mini">

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>
                إضافة مستخدم جديد
              </h1>
            </div>

            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="{name:'Users'}">
                    إدارة المستخدمين
                  </router-link>
                </li>
                <li class="breadcrumb-item active">
                  إضافة مستخدم
                </li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- jquery validation -->
              <div class="card card-primary">
                <div v-if="success_add" class="alert alert-success alert-dismissible fade show " role="alert">
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    <span class="sr-only">Close</span>
                  </button>
                  تمت اضافة السجل بنجاح
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form id="quickForm" @submit.prevent="addUser">
                   <span class="text-danger" v-if="errors">

           </span>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            اسم المستخدم
                          </label>

                          <input type="text" class="form-control"
                                 v-model="object.user_name"
                                 placeholder="أدخل اسم المستخدم">
                          <label class="text-danger"
                                 v-if="errors.user_name === 'The user name has already been taken.'">
                            الاسم موجود مسبقا الرجاء تغيير اسم المستخدم
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الاسم
                          </label>
                          <input type="text" name="" required class="form-control"
                                 v-model="object.name"
                                 placeholder="أدخل الاسم">
                          <label class="text-danger" v-if="errors.name">
                            يرجى اضافة الاسم
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            البريد الالكتروني
                          </label>
                          <input type="email" name="" class="form-control"
                                 v-model="object.email"
                                 placeholder="البريد الالكتروني">
                          <label class="text-danger" v-if="errors.email === 'The email has already been taken.'">
                            البريد الالكتروني موجود مسبقا يرجى تغييره
                          </label>
                          <label class="text-danger" v-else-if="errors.email !== null&&errors.email !== ''">
                            يرجى اضافة البريد الالكتروني
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            رقم الهاتف
                          </label>
                          <input type="number" name="" class="form-control"
                                 v-model="object.phone_number"
                                 placeholder="رقم الهاتف">
                          <label class="text-danger"
                                 v-if="errors.phone_number==='The phone number has already been taken.'">
                            رقم الهاتف موجود مسبقا الرجاء تغييره
                          </label>
                          <label class="text-danger"
                                 v-else-if="errors.phone_number!==null && errors.phone_number!=='undefined'&& errors.phone_number!==''">
                            رقم الهاتف خاطئ
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            كلمة السر
                          </label>
                          <input type="password" name="" class="form-control"
                                 v-model="object.password"
                                 placeholder="كلمة السر">

                          <label class="text-danger"
                                 v-if="errors.password === 'The password confirmation does not match.'">
                            كلمة السر غير مطابقة
                          </label>
                          <label class="text-danger"
                                 v-else-if="errors.password==='The password must be at least 8 characters.'">
                            الحد الأدنى لكلمة السر هو 8 محارف
                          </label>
                          <label class="text-danger" v-else-if="errors.password!==null&&errors.password!==''">
                            كلمة السر مطلوبة
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            تأكيد كلمة السر
                          </label>
                          <input type="password" name="" class="form-control"
                                 v-model="object.password_confirmation"
                                 placeholder=" تأكيد كلمة السر">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الصلاحية
                          </label>
                          <select class="custom-select" v-model="object.role">
                            <option value="AD">
                              مدير
                            </option>
                            <option value="AC">
                              مساعد مدير
                            </option>

                          </select>
                          <label class="text-danger" v-if="errors.role!==null">
                            الصلاحية مطلوبة
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <div class="custom-control custom-switch custom-switch-on-danger custom-switch-off-success">
                            <input type="checkbox" class="custom-control-input" id="customSwitch3"
                                   v-model="object.is_blocked">
                            <label class="custom-control-label" for="customSwitch3">
                              حظر / تفعيل
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <div class="custom-control custom-switch custom-switch-on-danger custom-switch-off-success">
                            <!--                            <ImageLoader :Images="object.image" keyName="id" valueName="i" />-->
                            <input type="file" @change="onChange" accept="image/*" >

                          </div>
                        </div>
                      </div>


                    </div>


                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button type="submit" class="btn " style="background-color: #8a7a25; color: white">حفظ</button>
                  </div>

                </form>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
            <div class="col-md-6">

            </div>
            <!--/.col (right) -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
// import ImageLoader from "vue-picture-upload";
// import fileBase64 from 'vue-file-base64';

export default {
  name: "AddUser",

  data() {
    return {
      success_add: false,
      errors: {
        password: null,
        email: null,
        phone_number: null,
        user_name: null,
        role: null,
      },
      object: {
        name: null,
        user_name: null,
        email: null,
        phone_number: null,
        password: null,
        password_confirmation: null,
        role: '',
        image: null,
        is_blocked: false
      }
    }
  }, methods: {
    onChange(e) {

      // get the files
      let files = e.target.files;

      // Process each file

      for (var i = 0; i < files.length; i++) {

        let file = files[i]

        // Make new FileReader
        let reader = new FileReader()

        // Convert the file to base64 text
        reader.readAsDataURL(file)

        // on reader load somthing...
        reader.onload = () => {

          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + ' kB',
            base64: reader.result,
            file: file
          }

          // Push it to the state
          this.object.image = fileInfo.base64

          // If all files have been proceed

          // Apply Callback function


        } // reader.onload

      } // for

    },

    addUser() {
      console.log(this.object.is_blocked)
      this.$store.dispatch("LOADING");
      this.$store.dispatch("ADD_USER", this.object)
          .then((res) => {

            console.log(res.data)
            // this.object = this.$store.state.users.selected
            this.$store.dispatch("STOP_LOADING");
            this.success_add = true;
            this.object = {
              name: null,
              user_name: null,
              email: null,
              phone_number: null,
              password: null,
              password_confirmation: null,
              role: '',
              is_blocked: false
            }
            this.errors = {
              password: null,
              email: null,
              phone_number: null,
              user_name: null,
              role: null,
            }
            // this.$router.push({name: 'Users'})
          }).catch((err) => {
        this.errors = err.response.data.errors;
        this.$store.dispatch("STOP_LOADING");
        this.errors.password = err.response.data.errors.password !== undefined ? this.errors.password[0] : null
        this.errors.email = err.response.data.errors.email !== undefined ? this.errors.email[0] : null
        this.errors.phone_number = err.response.data.errors.phone_number !== undefined ? this.errors.phone_number[0] : null
        this.errors.user_name = err.response.data.errors.user_name !== undefined ? this.errors.user_name[0] : null
        this.errors.role = err.response.data.errors.role !== undefined ? this.errors.role[0] : null
      }).finally(()=>{
        this.$store.dispatch("STOP_LOADING");
      });
    }
  },
  created() {

  }
}
</script>
<style lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>