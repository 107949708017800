<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <!--      <img src="/admin/dist/img/AdminLTELogo.png" alt="شركة النور للحوالات" class="brand-image img-circle elevation-3"-->
      <!--           style="opacity: .8">-->
      <span class="brand-text font-weight-light">
شركة النور للصرافة والحوالات
      </span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="/admin/dist/img/AdminLTELogo.png" class="img-circle  w-75 h-100" alt="User Image">
          <!--          <img :src="getImageProfile" class="img-circle elevation-2 w-100 h-100" alt="User Image">-->
        </div>
        <!--        <div class="info">-->
        <!--          <a href="#" class="d-block">Alexander Pierce</a>-->
        <!--        </div>-->
      </div>


      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->

          <li class="nav-item">
            <a href="#" class="nav-link" @click.prevent="getCurrenciesPricesActives">
              <i class="nav-icon fas fa-table"></i>
              <p>
                أسعار العملات
                <!--                <span class="right badge badge-danger">New</span>-->
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link" @click.prevent="getCurrencies">
              <i class="nav-icon fas fa-dollar-sign"></i>
              <p>
                إدارة العملات
                <!--                <span class="right badge badge-danger">New</span>-->
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" @click.prevent="getCurrenciesPrices">
              <i class="nav-icon fas fa-comment-dollar"></i>
              <p>

                إدارة أسعار العملات
                <!--                <span class="right badge badge-danger">New</span>-->
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" @click.prevent="getAds">
              <i class="nav-icon fas fa-ad"></i>
              <p>
                إدارة الإعلانات
                <!--                <span class="right badge badge-danger">New</span>-->
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" @click.prevent="getUsers">
              <i class="nav-icon fas fa-users-cog"></i>
              <p>
                إدارة المستخدمين
                <!--                <span class="right badge badge-danger">New</span>-->
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" @click.prevent="adminAboutUs">
              <i class="nav-icon fas fa-question-circle"></i>
              <p>
                من نحن
                <!--                <span class="right badge badge-danger">New</span>-->
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link" @click.prevent="adminLogout">
              <i class="nav-icon fas fa-sign-out-alt"></i>
              <p>
                تسجيل الخروج
                <!--                <span class="right badge badge-danger">New</span>-->
              </p>
            </a>
          </li>



        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "SideBar",
  methods: {
    adminLogout() {
      this.$store.dispatch("LOGOUT")
          .then((res) => {
            console.log(res)
            this.$router.push({name: 'Login'})
          }).catch((err) => {
        this.errors = err.response.data.errors;
      });
    },

    getUsers() {
      this.$router.push({name: 'Users'})
    },
    getAds() {
      this.$router.push({name: 'Ads'})
    },
    getCurrencies() {
      this.$router.push({name: 'Currencies'})
    },
    getCurrenciesPrices() {
      this.$router.push({name: 'CurrenciesPrices'})
    },
    getCurrenciesPricesActives() {
      this.$router.push({name: 'CurrenciesPricesActives'})
    },
    adminAboutUs() {
      this.$router.push({name: 'AboutUs'})
    }
  },
  computed: {
    // getImageProfile() {
    //   return this.$store.getters.GET_AUTH_INFO.image.thumbnail;
    // return this.$store.getters.GET_AUTH_INFO.image.src;
    // }
  }


}
</script>

<style scoped>

</style>