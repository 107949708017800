<template>
  <div class="hold-transition sidebar-mini">

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->

      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>
                من نحن
              </h1>
            </div>


          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- jquery validation -->
              <div class="card card-primary">
                <div v-if="success_add" class="alert alert-success alert-dismissible fade show " role="alert">
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    <span class="sr-only">Close</span>
                  </button>
                  تمت تعديل السجل بنجاح
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form id="quickForm" @submit.prevent="updateAboutUs">
                   <span class="text-danger" v-if="errors">

           </span>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            رؤيتنا
                          </label>
                          <textarea v-model="object.our_vision" class="form-control" cols="5"
                                    placeholder="رؤيتنا  "></textarea>

                          <label class="text-danger" v-if="errors.our_vision">
                            يرجى اضافة الرؤية
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            رسالتنا
                          </label>
                          <textarea v-model="object.our_message" class="form-control" cols="5"
                                    placeholder="رسالتنا  "></textarea>


                          <label class="text-danger" v-if="errors.our_message">
                            يرجى اضافة الرسالة
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            واتس قسم الحوالات
                          </label>
                          <input type="number" name="" class="form-control"
                                 v-model="object.phone_assistant"
                                 placeholder="قسم الحوالات ">

                          <label class="text-danger"
                                 v-if="errors.phone_assistant !== null&&errors.phone_assistant !== ''">
                            يرجى اضافة رقم قسم الحوالات
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            واتس قسم الصرافة
                          </label>
                          <input type="number" name="" class="form-control"
                                 v-model="object.phone_manager"
                                 placeholder="رقم قسم الصرافة ">

                          <label class="text-danger"
                                 v-if="errors.phone_manager!==null && errors.phone_manager!=='undefined'&& errors.phone_manager!==''">
                            رقم قسم الصرافة مطلوب
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الموقع الالكتروني
                          </label>
                          <input type="text" name="" class="form-control"
                                 v-model="object.website"
                                 placeholder="الموقع الالكتروني ">


                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            حساب فيس بوك
                          </label>
                          <input type="text" name="" class="form-control"
                                 v-model="object.facebook"
                                 placeholder="رابط صفحة الفيس بوك">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            اسم الشركة
                          </label>
                          <input type="text" class="form-control"
                                 v-model="object.name"
                                 placeholder="اسم الشركة  ">

                          <label class="text-danger"
                                 v-if="errors.name !== null&&errors.name !== ''">
                            يرجى اضافة اسم الشركة
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            العنوان
                          </label>
                          <input type="text" class="form-control"
                                 v-model="object.insta"
                                 placeholder="عنوان الشركة  ">

                          <label class="text-danger"
                                 v-if="errors.insta !== null&&errors.insta !== ''">
                            يرجى اضافة العنوان
                          </label>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                           ملاحظة
                          </label>
                          <input type="text" name="" class="form-control"
                                 v-model="object.desc"
                                 placeholder="ملاحظة  ">


                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button type="submit" class="btn " style="background-color: #8a7a25; color: white">حفظ</button>
                  </div>

                </form>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
            <div class="col-md-6">

            </div>
            <!--/.col (right) -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
// import ImageLoader from "vue-picture-upload";
// import fileBase64 from 'vue-file-base64';

export default {
  name: "AboutUs",

  data() {
    return {
      success_add: false,
      errors: {
        our_vision: null,
        our_message: null,
        phone_manager: null,
        phone_assistant: null,
        facebook: null,
        insta: null,
        website: null,
        desc: null,
        name: null
      },
      object: {
        key: 'company_info',
        our_vision: null,
        our_message: null,
        phone_manager: null,
        phone_assistant: null,
        facebook: '',
        insta: '',
        website: null,
        desc: '',
        name: null
      }
    }
  }, methods: {

    updateAboutUs() {

      this.$store.dispatch("LOADING");
      this.$store.dispatch("UPDATE_ABOUT_US", this.object)
          .then((res) => {

            console.log(res.data)
            // this.object = this.$store.state.users.selected
            this.$store.dispatch("STOP_LOADING");
            this.success_add = true;

            this.errors = {
              our_vision: null,
              our_message: null,
              phone_manager: null,
              phone_assistant: null,
              facebook: null,
              insta: null,
              website: null,
              desc: null,
              name: null
            }
            // this.$router.push({name: 'Users'})
          }).catch((err) => {
        this.errors = err.response.data.errors;
        this.$store.dispatch("STOP_LOADING");
        // this.errors.password = err.response.data.errors.password !== undefined ? this.errors.password[0] : null
        // this.errors.email = err.response.data.errors.email !== undefined ? this.errors.email[0] : null
        // this.errors.phone_number = err.response.data.errors.phone_number !== undefined ? this.errors.phone_number[0] : null
        // this.errors.user_name = err.response.data.errors.user_name !== undefined ? this.errors.user_name[0] : null
        // this.errors.role = err.response.data.errors.role !== undefined ? this.errors.role[0] : null
      }).finally(() => {
        this.$store.dispatch("STOP_LOADING");
      });
    },
    getAboutUs() {

      this.$store.dispatch("LOADING");
      this.$store.dispatch("GET_ABOUT_US")
          .then((res) => {

            console.log(res.data)
            this.object = this.$store.state.setting.about_us
            this.$store.dispatch("STOP_LOADING");

            // this.$router.push({name: 'Users'})
          }).catch((err) => {
        this.errors = err.response.data.errors;
        this.$store.dispatch("STOP_LOADING");
        // this.errors.password = err.response.data.errors.password !== undefined ? this.errors.password[0] : null
        // this.errors.email = err.response.data.errors.email !== undefined ? this.errors.email[0] : null
        // this.errors.phone_number = err.response.data.errors.phone_number !== undefined ? this.errors.phone_number[0] : null
        // this.errors.user_name = err.response.data.errors.user_name !== undefined ? this.errors.user_name[0] : null
        // this.errors.role = err.response.data.errors.role !== undefined ? this.errors.role[0] : null
      }).finally(() => {
        this.$store.dispatch("STOP_LOADING");
      });
    }
  },
  created() {
    this.getAboutUs()
  }
}
</script>
<style lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>