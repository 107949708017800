import axios from '../main_axios';
export const auth = {
    state: {
        auth_status: false,
        auth_token: null,
        user_info: {
            id: null,
            user_name: null,
            name: null,
            email: null,
            image: null,
            phone_number: null,
            user_role: null
        }
    },
    getters: {
        GET_AUTH_STATUS(state) {
            return state.auth_status;
        },
        GET_AUTH_TOKEN(state) {
            return state.auth_token;
        },
        GET_AUTH_INFO(state) {
            return state.user_info;
        },

    },
    actions: {
        LOGIN(context, loginData) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/login', loginData).then((response) => {
                    context.commit('SET_AUTH_TOKEN', response.data.data.access_token)

                    context.commit('SET_USER_INFO', response.data.data.user)
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },
        LOGOUT(context) {
            axios.defaults.headers.common['Authorization'] ='Bearer '+ context.state.auth_token;
            return new Promise((resolve, reject) => {
                axios.post('/auth/logout').then((response) => {
                    context.commit('SET_AUTH_LOGOUT')
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },
    },
    mutations: {
        SET_AUTH_TOKEN(state, token) {
            state.auth_token = token;
            state.auth_status = true;
        },
        SET_USER_INFO(state, user) {

            state.user_info.id = user.id;
            state.user_info.email = user.email;
            state.user_info.user_name = user.user_name;
            state.user_info.name = user.name;
            state.user_info.phone_number = user.phone_number;
            state.user_info.user_role = user.user_role;
            state.user_info.image = user.image;
        },
        SET_AUTH_LOGOUT(state) {
            state.auth_token = null;
            state.auth_status = false;
            state.user_info = {
                id: null,
                user_name: null,
                name: null,
                email: null,
                image: null,
                phone_number: null,
                user_role: null
            }


        },
    }

}