import axios from '../main_axios';
import store from "../index";

export const currencies_prices = {
    state: {
        data: [],
        data_active: [],
        selected: null,
        count: 0,

    },
    getters: {},
    actions: {

        GET_LIST_CURRENCIES_PRICES(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/currency-price').then((response) => {
                    var list = []
                    response.data.data.map(function (value) {
                        list.push(value)
                    })
                    context.commit('GET_LIST_CURRENCIES_PRICES', list)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },

        GET_LIST_ACTIVE_CURRENCIES_PRICES(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/currency-price/active').then((response) => {
                    // var list = []
                    // response.data.data.map(function (value) {
                    //     list.push(value)
                    // })
                    console.log(response.data.data)
                    context.commit('GET_LIST_CURRENCIES_ACTIVE_PRICES', response.data.data)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },

        SHOW_CURRENCY_PRICE(context, id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/currency-price/' + id).then((response) => {

                    context.commit('SET_CURRENCY_PRICE', response.data.data)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        UPDATE_CURRENCY_PRICE(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/currency-price/update', payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        UPDATE_DOWNLOAD(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/admin/currency-price/count-download').then((response) => {
                    context.commit('SET_COUNT_DOWNLOAD', response.data.data)
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        TOGGLE_ACTIVE_CURRENCY_PRICE(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/admin/currency-price/toggle-active', payload).then((response) => {

                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })
        }

    },
    mutations: {
        GET_LIST_CURRENCIES_PRICES(state, items) {
            state.data = items;
        },
        GET_LIST_CURRENCIES_ACTIVE_PRICES(state, items) {
            state.data_active = items;
        },
        SET_CURRENCY_PRICE(state, item) {
            state.selected = item;
        },
        SET_COUNT_DOWNLOAD(state, item) {
            state.count = item;
        }

    }

}