import axios from '../main_axios';
import store from "../index";

export const setting = {
    state: {
        about_us: {
            our_vision: null,
            our_message: null,
            phone_manager: null,
            phone_assistant: null,
            facebook: '',
            insta: '',
            website: null,
            desc: '',
            name: null
        },
    },
    getters: {},
    actions: {
        UPDATE_ABOUT_US(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.post('/manager/company_info', payload).then((response) => {

                    context.commit('SET_ABOUT_US', response.data.data.value)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },
        GET_ABOUT_US(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.GET_AUTH_TOKEN;
            return new Promise((resolve, reject) => {
                axios.get('/manager/company-info').then((response) => {

                    context.commit('SET_ABOUT_US', response.data.data.value)


                    resolve(response)
                }).catch((error) => {
                    reject(error)
                });
            })

        },

    },
    mutations: {
        SET_ABOUT_US(state, items) {
            state.about_us.desc = items.desc;
            state.about_us.name = items.name;
            state.about_us.website = items.website;
            state.about_us.our_vision = items.our_vision;
            state.about_us.our_message = items.our_message;
            state.about_us.phone_assistant = items.phone_assistant;
            state.about_us.phone_manager = items.phone_manager;
            state.about_us.insta = items.insta;
            state.about_us.facebook = items.facebook;
        },


    }

}