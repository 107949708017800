<template>
  <div class="content-wrapper ">
    <div class="content-header ">
      <div class="container-fluid  mt-5">
        <div class="row mb-2">
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-left">
              <li class="breadcrumb-item">
                <router-link :to="{name:'AddCurrency'}" class="btn " style="background-color: #8a7a25; color: white">
                  إضافة عملة  جديدة
                </router-link>
              </li>
            </ol>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">

                <h1 class="m-0">
                  إدارة العملات
                </h1>

              </li>

            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">


            <!-- /.card-header -->

            <table id="example1" class="table table-striped">
              <thead>
              <tr>
                <th>
                  اسم العملة
                </th>

                <th>
                  رمز العملة
                </th>


                <th>

                </th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="data in currencies" :key="data.id">
                <td>{{ data.name }}</td>
                <td class="english-font">{{ data.code }}</td>
                <td>
                  <router-link :to="'/admin/currencies/show/'+data.id">
                    تعديل
                  </router-link>

                </td>
              </tr>


              </tbody>

            </table>

            <!-- /.card-body -->

          </div>
        </div>
      </div>
    </section>
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Currencies",
  data() {
    return {
      currencies: [],
      errors: {}
    }
  }, computed: {},
  methods: {
    getList() {
      this.$store.dispatch("LOADING");
      this.$store.dispatch("GET_LIST_CURRENCIES")
          .then((res) => {
            console.log(res.data)
            this.currencies = this.$store.state.currencies.data
            this.$store.dispatch("STOP_LOADING");
          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;
      });
    }
  },
  created() {
    this.getList()
  }
}

</script>

<style lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>