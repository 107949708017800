import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import {auth} from './modules/auth'
import {users} from "./modules/users";
import {loading} from "./modules/loading";
import {currencies} from "./modules/currencies";
import {currencies_prices} from "./modules/currenciesPrice";
import {ads} from "./modules/ads";
import {setting} from "./modules/setting";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    users,
    loading,
    currencies,
    currencies_prices,
    ads,
    setting
  },
  plugins: [createPersistedState()],
})
