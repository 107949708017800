<template>
  <div class="hold-transition sidebar-mini">

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>{{ object.name }}</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="{name:'Ads'}">
                    إدارة الإعلانات
                  </router-link>
                </li>
                <li class="breadcrumb-item active">تعديل الإعلان</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- jquery validation -->
              <div class="card card-primary">

                <!-- /.card-header -->
                <!-- form start -->
                <form id="quickForm" @submit.prevent="updateAd">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-6">
                        <a href="#" class="brand-link">
                          <img :src="urlImage!== null?urlImage:object.image.src"
                               alt="User Avatar"
                               class="img-fluid mb-2"
                               style="opacity: .8;height: 250px;width: 400px">

                        </a>
                      </div>

                    </div>
                    <div class="row">

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الاسم
                          </label>
                          <input type="text" name="" class="form-control"
                                 v-model="object.name"
                                 placeholder="أدخل الاسم">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="ml-5">
                            اختر الصورة
                          </label>
                          <div
                              class="custom-control custom-switch custom-switch-on-danger custom-switch-off-success">
                            <!--                            <ImageLoader :Images="object.image" keyName="id" valueName="i" />-->
                            <input type="file" @change="onChange" accept="image/*">

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">

                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger">
                            <input type="checkbox" class="custom-control-input" id="customSwitch3"
                                   v-model="object.is_active"
                                   :checked="object.is_active">
                            <label class="custom-control-label" for="customSwitch3">
                              تفعيل /حظر
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button type="submit" class="btn " style="background-color: #8a7a25; color: white">حفظ</button>
                  </div>
                </form>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
            <div class="col-md-6">

            </div>
            <!--/.col (right) -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <div v-if="this.$store.getters.GET_LOADING_STATUS" :id="this.$store.getters.GET_LOADING_STATUS?'overlay':''">
      <div id="text-overlay">
        ... جاري التحميل
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowUser",
  data() {
    return {
      urlImage: null,
      object: {}
    }
  }, methods: {
    getObject() {

      this.$store.dispatch("SHOW_AD", this.$route.params.id)
          .then((res) => {
            console.log(res.data)
            this.object = this.$store.state.ads.selected

          }).catch((err) => {
        this.errors = err.response.data.errors;
      });
    },
    updateAd() {
      this.$store.dispatch("LOADING");
      var payload = {
        ad_id: this.$route.params.id,
        name: this.object.name,
        image: typeof (this.object.image) === 'string' ? this.object.image : this.object.image.src,
        is_active: this.object.is_active
      }
      this.$store.dispatch("UPDATE_AD", payload)
          .then((res) => {
            console.log(res.data)
            this.object = this.$store.state.ads.selected
            this.$store.dispatch("STOP_LOADING");
            this.$router.push({name:'Ads'})

          }).catch((err) => {
        this.$store.dispatch("STOP_LOADING");
        this.errors = err.response.data.errors;
      });
    },
    onChange(e) {

      // get the files
      let files = e.target.files;

      // Process each file

      for (var i = 0; i < files.length; i++) {

        let file = files[i]

        // Make new FileReader
        let reader = new FileReader()

        // Convert the file to base64 text
        reader.readAsDataURL(file)

        // on reader load somthing...
        reader.onload = () => {

          // Make a fileInfo Object
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + ' kB',
            base64: reader.result,
            file: file
          }
          this.urlImage = URL.createObjectURL(file);
          // Push it to the state
          this.object.image = fileInfo.base64

          // If all files have been proceed

          // Apply Callback function


        } // reader.onload

      } // for

    },

  },
  created() {
    this.getObject()
  }
}
</script>

<style lang="css">

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>