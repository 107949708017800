
export const loading = {
    state: {
        loading_status: false,

    },
    getters: {
        GET_LOADING_STATUS(state) {
            return state.loading_status;
        },


    },
    actions: {
        LOADING(context) {
            context.commit('SET_LOADING', true)
        },
        STOP_LOADING(context) {
            context.commit('SET_LOADING', false)
        },

    },
    mutations: {
        SET_LOADING(state,status) {
            state.loading_status = status;
        },

    }

}