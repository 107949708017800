<template>
  <div class="hold-transition sidebar-mini">

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>{{object.name}}</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><router-link :to="{name:'Users'}">
                  إدارة المستخدمين
                </router-link></li>
                <li class="breadcrumb-item active">تعديل المستخدم</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- jquery validation -->
              <div class="card card-primary">

                <!-- /.card-header -->
                <!-- form start -->
                <form id="quickForm">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-6">
                        <a href="#" class="brand-link">
                          <img :src="object.image!== null?object.image.src:null"
                               alt="User Avatar"
                               class="  elevation-3"
                               style="opacity: .8;height: 250px;width: 400px">

                        </a>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            اسم المستخدم
                          </label>
                          <input type="text"  class="form-control"
                                 v-model="object.user_name"
                                 placeholder="أدخل اسم المستخدم">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الاسم
                          </label>
                          <input type="text" name="" class="form-control"
                                 v-model="object.name"
                                 placeholder="أدخل الاسم">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            البريد الالكتروني
                          </label>
                          <input type="email" name="" class="form-control"
                                 v-model="object.email"
                                 placeholder="البريد الالكتروني">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                          رقم الهاتف
                          </label>
                          <input type="number" name="" class="form-control"
                                 v-model="object.phone_number"
                                 placeholder="رقم الهاتف">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            كلمة السر
                          </label>
                          <input type="password" name="" class="form-control"
                                 placeholder="كلمة السر">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            تأكيد كلمة السر
                          </label>
                          <input type="password" name="" class="form-control"
                                 placeholder=" تأكيد كلمة السر">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            الصلاحية
                          </label>
                          <select class="custom-select" v-model="object.user_role">
                            <option id="AD">
                              مدير
                            </option>
                            <option id="AC">
                              مساعد مدير
                            </option>

                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                            <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="object.is_blocked">
                            <label class="custom-control-label" for="customSwitch3">
                          حظر / تفعيل
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <button type="submit" class="btn " style="background-color: #8a7a25; color: white">حفظ</button>
                  </div>
                </form>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
            <div class="col-md-6">

            </div>
            <!--/.col (right) -->
          </div>
          <!-- /.row -->
        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

  </div>
</template>

<script>
export default {
  name: "ShowUser",
  data() {
    return {
      object: {}
    }
  }, methods: {
    getObject() {

      this.$store.dispatch("SHOW_USER", this.$route.params.id)
          .then((res) => {
            console.log(res.data)
            this.object = this.$store.state.users.selected

          }).catch((err) => {
        this.errors = err.response.data.errors;
      });
    },
    updateUser(){

    }
  },
  created() {
    this.getObject()
  }
}
</script>

<style scoped>

</style>